@font-face {
    font-family: 'PT Sans';
    src: url('https://www.hachette-vins.com/fonts/PT_Sans-Web-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PT Sans';
    src: url('https://www.hachette-vins.com/fonts/PT_Sans-Web-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'PT Sans';
    src: url('https://www.hachette-vins.com/fonts/PT_Sans-Web-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PT Sans';
    src: url('https://www.hachette-vins.com/fonts/PT_Sans-Web-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('https://www.hachette-vins.com/fonts/Raleway-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('https://www.hachette-vins.com/fonts/Raleway-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('https://www.hachette-vins.com/fonts/Raleway-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Grand Hotel';
    src: url('https://www.hachette-vins.com/fonts/GrandHotel-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueMedium';
    src: url(https://www.hachette-vins.com/bundles/ghvcore/fonts/HelveticaNeueLT_Std_Light.eot?#iefix);
    src: url(https://www.hachette-vins.com/bundles/ghvcore/fonts/HelveticaNeueLT_Std_Light.eot?#iefix) format("embedded-opentype"),
    url(https://www.hachette-vins.com/bundles/ghvcore/fonts/HelveticaNeueLT_Std_Light.woff) format("woff"),
    url(https://www.hachette-vins.com/bundles/ghvcore/fonts/HelveticaNeueLT_Std_Light.ttf) format("truetype"),
    url(https://www.hachette-vins.com/bundles/ghvcore/fonts/HelveticaNeueLT_Std_Light.svg#HelveticaNeueLT_Std_Light) format("svg");
    font-weight: 400;
    font-style: normal;
}